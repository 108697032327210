import "./style.scss";

import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonButton, IonLabel, IonSpinner } from "@ionic/react";
import { OpenNativeSettings } from "@ionic-native/open-native-settings";
import { api } from "@src/app/api";
import {
  AppType,
  LocationAlertType,
  LocationAlertTypeReason,
  LocationType,
  getOrWatchCurrentLocation,
} from "@src/app/common/location";
import {
  EARLY_BREAK_ALERT_THRESHOLD_PERCENTAGE,
  EARLY_CLOCK_IN_ENABLE_LIMIT_IN_MINUTES,
  EARLY_CLOCK_OUT_ALERT_THRESHOLD_PERCENTAGE,
  MANDATORY_BREAK_DURATION_ALERT_THRESHOLD_IN_MINUTES,
  MANDATORY_BREAK_DURATION_IN_MINUTES,
  MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS,
} from "@src/app/hcpShifts/constants";
import { getGeofenceStatus } from "@src/app/hcpShifts/custom-hooks/getGeofenceStatus";
import { useGeolocationTrackingForShiftsEnabled } from "@src/app/hcpShifts/custom-hooks/useGeolocationTrackingForShiftsEnabled";
import { useRadarLocationApi } from "@src/app/hcpShifts/custom-hooks/useRadarLocationHook";
import { shiftTripStorageHelper } from "@src/app/locationTracking/shiftTripStorage.helper";
import { useNetworkHealthCheck } from "@src/app/networkHealthCheck/useNetworkHealthCheck";
import { useSession } from "@src/app/store/helperHooks";
import { ActionType } from "@src/app/store/ongoingShifts/model";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { isCapacitorPlatform, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import {
  LegacyGeoLocationCoordinates,
  calculateGeoDistanceInMiles,
  convertToGeoLocation,
} from "@src/appV2/Location";
import { useGetPolicyAcknowledgement } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/useGetPolicyAcknowledgement";
import { usePostPolicyAcknowledgement } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/usePostPolicyAcknowledgement";
import { MandatoryBreakPolicyDialog } from "@src/appV2/Shifts/MandatoryBreakPolicy/MandatoryBreakPolicyDialog";
import { NoteAcknowledgementAction } from "@src/appV2/Shifts/MandatoryBreakPolicy/types";
import { ClockOutDialog } from "@src/appV2/Shifts/Shift/ClockOutDialog/Dialog";
import { ShiftStateData } from "@src/appV2/Shifts/Shift/ShiftState/types";
import { FacilityNoteIdentifier } from "@src/constants/facility";
import { BRAZE_CUSTOM_EVENTS, USER_EVENTS } from "@src/constants/userEvents";
import {
  LegacyGeoLocationCoordinatesAndType,
  SHIFT_MARKED_NON_IP_REASONS,
  Shift,
  ShiftStages,
} from "@src/lib/interface";
import { getHumanReadableTag } from "@src/lib/utils";
import { actionRecordShiftTime } from "@store/ongoingShifts";
import * as AppboyPlugin from "appboy-cordova-sdk/www/AppboyPlugin";
import { differenceInMinutes, isAfter, parseISO, subMinutes } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { forcePedometerPermissionPrompt, recordShiftPedometerData } from "./pedometer.utils";
import { SkipLunchButton } from "./skipLunchButton";
import { getStageObject } from "./Stage";
import { makeInstantpayLogParameters } from "../../../utils/logEvent";
import {
  formatRate,
  getShiftBreakDuration,
  getShiftCompletionPercentage,
} from "../../hcpShifts/helper";
import { ShiftDetailsAlerts } from "../../hcpShifts/shiftDetails/alerts";
import { actionRecordShiftTimeFailure } from "../../store/ongoingShifts";
import { checkLocationAwareness } from "../../utils/locationHelper";

const FACILITY_SAFE_DISTANCE_MILES = 0.75;
const RECORD_TIME_BUTTON_STATE_CHECK_IN_MILLIS = 1_000;

interface InstantShiftButtonProps {
  shift: Shift;
  shiftStateData?: ShiftStateData;
  uploadTimeSheet: (boolean?: boolean) => void;
  onClickNoTimeSheet?: () => void;
  loadShiftDetails?: any;
  shiftDetailsAlerts: ShiftDetailsAlerts;
  isNewTimeSheetEnabled: boolean;
  isSignatureSubmission: boolean;
  isNFCEnabled: boolean;
  isDigitalSignatureEnabled: boolean;
  isCaliforniaTimesheetEnabled: boolean;
  isSolveUnpaidBreaksEnabled: boolean;
}

interface ShowTooFarAwayProps {
  failureReason: string;
  stage: ShiftStages;
  isLocationExperienceEnabled?: boolean;
  location?: [long: number, lat: number];
  setActionLoading: (_: boolean) => void;
}

export function InstantShiftButton(props: InstantShiftButtonProps) {
  const {
    shift,
    shiftStateData,
    loadShiftDetails,
    uploadTimeSheet,
    onClickNoTimeSheet,
    shiftDetailsAlerts,
    isNewTimeSheetEnabled,
    isSignatureSubmission,
    isNFCEnabled,
    isDigitalSignatureEnabled,
    isCaliforniaTimesheetEnabled,
    isSolveUnpaidBreaksEnabled,
  } = props;
  const { showErrorToast } = useToast();
  const { agent = {}, env } = useSession();
  const dispatch = useDispatch();

  const [isTimekeepingActionLoading, setIsTimekeepingActionLoading] = useState<boolean>(false);
  const { currentStage, currentStageText, currentStageLog } = getStageObject({
    shift,
    isSignatureSubmission: isNewTimeSheetEnabled && isSignatureSubmission,
    isCaliforniaTimesheetEnabled,
    isSolveUnpaidBreaksEnabled,
  });
  const history = useHistory();

  const [isRecordTimeButtonEnabled, setIsRecordTimeButtonEnabled] = useState(true);

  const { checkFacilityGeofence } = useRadarLocationApi(
    shift.facility?.userId?.toString() ?? "",
    shift._id?.toString() ?? ""
  );
  const geolocationTrackingForShiftsEnabled = useGeolocationTrackingForShiftsEnabled(shift);

  const isNFCCheckNeeded = isNFCEnabled && currentStage !== ShiftStages.SHIFT_TIME_DONE;
  const isMandatoryBreakEnabled = shiftStateData?.metadata.requiresLunchBreak ?? false;

  const breakDuration = getShiftBreakDuration(shift);
  const remainingAmount = 0;

  const isNewClockOutModalEnabled = useCbhFlag(CbhFeatureFlag.NEW_CLOCK_OUT_MODAL, {
    defaultValue: false,
  });
  const clockOutDialogModalState = useModalState();

  const { noteId: mandatoryBreakPolicyNoteId, note: mandatoryBreakPolicyNoteContent } =
    shift.facility?.facilityNotes?.find(
      (note) => note.identifier === FacilityNoteIdentifier.MANDATORY_BREAK_POLICY
    ) ?? {};
  const isBreakPolicyAcknowledgementRequired = Boolean(
    isSolveUnpaidBreaksEnabled &&
      isMandatoryBreakEnabled &&
      isDefined(shift.end) &&
      isDefined(shift.start) &&
      differenceInMinutes(parseISO(shift.end), parseISO(shift.start)) >
        MINIMUM_TIME_FOR_MANDATORY_BREAK_IN_HOURS * 60 &&
      mandatoryBreakPolicyNoteId
  );
  /**
    @deprecated - This code pattern is fragile and ought to be replaced
    with individual hooks and components. Do not use this pattern elsewhere in code.
    This is necessary to keep the same behavior as before, where dialogs are being
    awaited in a synchronous manner.
  */
  const deprecatedBreakPolicyAcceptedCallbackRef = useRef<
    undefined | ((accepted: boolean) => void)
  >();
  const mandatoryBreakPolicyDialogState = useModalState();

  const { mutate: postBreakPolicyAcknowledgement } = usePostPolicyAcknowledgement();
  const {
    data: breakPolicyAcknowledgement,
    refetch: refetchBreakPolicyAcknowledgement,
    isLoading: isBreakPolicyAcknowledgmentLoading,
  } = useGetPolicyAcknowledgement(
    {
      policyAcknowledgementAction: NoteAcknowledgementAction.CLOCK_IN,
      noteId: mandatoryBreakPolicyNoteId ?? undefined,
    },
    { enabled: isBreakPolicyAcknowledgementRequired }
  );
  const isBreakPolicyAcknowledged =
    isDefined(breakPolicyAcknowledgement) && breakPolicyAcknowledgement.data.length > 0;
  const isBreakPolicyLoading =
    currentStage === ShiftStages.CLOCK_IN &&
    isBreakPolicyAcknowledgementRequired &&
    isBreakPolicyAcknowledgmentLoading;

  useNetworkHealthCheck();

  const biometricQualitySignalExperimentFlag = useCbhFlag(
    CbhFeatureFlag.BIOMETRIC_QUALITY_SIGNAL_EXPERIMENT,
    {
      defaultValue: false,
    }
  );

  useEffect(() => {
    let buttonEnableInterval: NodeJS.Timeout;

    function resolveRecordTimeButtonState() {
      if (isDefined(shift?.start)) {
        const earlyClockInLimit = shiftStateData?.metadata.isEarlyClockInEnabled
          ? EARLY_CLOCK_IN_ENABLE_LIMIT_IN_MINUTES
          : 0;
        const isClockInTimeThresholdPassed = isAfter(
          new Date(),
          subMinutes(new Date(shift.start), earlyClockInLimit)
        );

        if (currentStage === ShiftStages.CLOCK_IN) {
          setIsRecordTimeButtonEnabled(isClockInTimeThresholdPassed);
        }
      }
    }

    if (currentStage === ShiftStages.CLOCK_IN) {
      resolveRecordTimeButtonState();
      buttonEnableInterval = setInterval(
        resolveRecordTimeButtonState,
        RECORD_TIME_BUTTON_STATE_CHECK_IN_MILLIS
      );
    } else {
      setIsRecordTimeButtonEnabled(true);
    }
    return () => clearInterval(buttonEnableInterval);
  }, [currentStage, env, shift.start, shiftStateData?.metadata.isEarlyClockInEnabled]);

  const logBreakPolicyAcknowledgementEvent = (
    eventType: (typeof USER_EVENTS)[keyof typeof USER_EVENTS]
  ) => {
    logEvent(eventType, {
      shiftId: shift._id,
      facilityId: shift.facility?.userId,
      agentId: agent.userId,
      action: NoteAcknowledgementAction.CLOCK_IN,
      noteId: mandatoryBreakPolicyNoteId,
    });
  };

  const openLocationSettings = async () => {
    if (isCapacitorPlatform()) {
      await OpenNativeSettings.open("location");
    } else {
      setTimeout(() => {
        shiftDetailsAlerts.alertBrowserLocationAccess();
      }, 500);
    }
  };

  const goToNFCInstructionsPage = (stage: ShiftStages) => {
    history.push({
      pathname: `/home/myShifts/${shift._id}/nfcInstructions`,
      state: {
        currentStage: stage,
        shift,
        areBreaksMandatory: Boolean(shiftStateData?.metadata.requiresLunchBreak),
        isSolveUnpaidBreaksEnabled,
      },
    });
  };

  const showMandatoryBreakEarlyEndConfirmation = () => {
    return new Promise<{ shouldProceed: boolean }>((resolve) => {
      logEvent(
        USER_EVENTS.TAPPED_END_BREAK_EARLY,
        makeInstantpayLogParameters(
          shift,
          shift.isInstantPay,
          undefined,
          shiftStateData?.metadata.requiresLunchBreak
        )
      );
      shiftDetailsAlerts.alertMandatoryBreakEarlyEndConfirmation({
        timeRemainingMinutes: MANDATORY_BREAK_DURATION_IN_MINUTES - breakDuration,
        proceedHandler: () => resolve({ shouldProceed: true }),
        cancelHandler: () => {
          logEvent(
            USER_EVENTS.BREAK_END_EARLY_CANCEL,
            makeInstantpayLogParameters(
              shift,
              shift.isInstantPay,
              undefined,
              shiftStateData?.metadata.requiresLunchBreak
            )
          );
          resolve({ shouldProceed: false });
        },
      });
    });
  };

  const showEarlyClockOutConfirmation = () => {
    const instantPayLogParams = makeInstantpayLogParameters(
      shift,
      shift.isInstantPay,
      undefined,
      shiftStateData?.metadata.requiresLunchBreak
    );

    return new Promise<boolean>((resolve) => {
      logEvent(USER_EVENTS.TAPPED_CLOCK_OUT_EARLY, instantPayLogParams);
      shiftDetailsAlerts.alertEarlyClockOutConfirmation({
        proceedHandler: () => {
          logEvent(USER_EVENTS.CLOCKED_OUT_EARLY, instantPayLogParams);
          resolve(true);
        },
        cancelHandler: () => {
          logEvent(USER_EVENTS.CANCELLED_CLOCKED_OUT_EARLY, instantPayLogParams);
          resolve(false);
        },
      });
    });
  };

  const clockBtnHandler = async (
    id: string,
    stage: ShiftStages,
    location: [Longitude: number, Latitude: number] | undefined,
    shift: Shift,
    setActionLoading: (_: boolean) => void
  ) => {
    dispatch(
      actionRecordShiftTime(
        id,
        stage,
        location as number[],
        LocationType.LIVE,
        isCapacitorPlatform() ? AppType.MOBILE : AppType.WEB,
        ({ error }) => {
          if (!error) {
            loadShiftDetails(shift._id);
            setActionLoading(false);
          } else {
            history.push("/home/openShifts");
          }
        }
      )
    );
    const trackEventProperties = makeInstantpayLogParameters(
      shift,
      shift.isInstantPay,
      undefined,
      Boolean(shiftStateData?.metadata.requiresLunchBreak)
    );
    if (isDefined(currentStageLog)) {
      logEvent(currentStageLog, trackEventProperties);
    }

    if (isCapacitorPlatform() && currentStage === ShiftStages.CLOCK_IN) {
      AppboyPlugin.logCustomEvent(BRAZE_CUSTOM_EVENTS.CLOCKED_IN, trackEventProperties);
      if (shift.isInstantPay) {
        shiftTripStorageHelper.cleanUpConcurrentShifts(id);
      }
    }

    if (stage === ShiftStages.SKIP_LUNCH) {
      logEvent(USER_EVENTS.SKIP_BREAK, trackEventProperties);
    }
    if (
      stage === ShiftStages.LUNCH_IN &&
      breakDuration < MANDATORY_BREAK_DURATION_ALERT_THRESHOLD_IN_MINUTES
    ) {
      logEvent(USER_EVENTS.BREAK_END_EARLY, {
        ...trackEventProperties,
        breakDuration,
      });
    }
    if (stage === ShiftStages.CLOCK_OUT) {
      showUploadNowOrLaterModal();
    }

    setActionLoading(false);

    if (biometricQualitySignalExperimentFlag) {
      if (stage === ShiftStages.CLOCK_IN) {
        // This will only prompt the user if permission status hasn't yet been determined
        await forcePedometerPermissionPrompt(shift);
      } else if (stage === ShiftStages.CLOCK_OUT) {
        await recordShiftPedometerData(shift);
      }
    }
  };

  const isHyperTrackEnabledForClockInOut = useCbhFlag(CbhFeatureFlag.HYPERTRACK_CLOCK_IN_OUT, {
    defaultValue: false,
  });

  const showTooFarAwayPopup = (props: ShowTooFarAwayProps) => {
    const { failureReason, setActionLoading, stage, isLocationExperienceEnabled, location } = props;
    const shiftId = shift._id!;
    dispatch(actionRecordShiftTimeFailure(failureReason, agent, shiftId));
    logEvent(USER_EVENTS.TOO_FAR_AWAY_PANEL, {
      shiftId,
      isLocationExperienceEnabled,
      location,
      stage,
      facilityLocation: shift.facility?.geoLocation?.coordinates,
    });

    if (stage !== currentStage) {
      logEvent("ShowTooFarAwayPopup", {
        message: "Different stage values in props from getStageObject",
      });
    }

    if (isCapacitorPlatform() && stage === ShiftStages.CLOCK_IN) {
      AppboyPlugin.logCustomEvent(BRAZE_CUSTOM_EVENTS.TOO_FAR_AWAY_PANEL, {
        shiftId: shiftId,
        hcfId: shift.facilityId ?? shift.facility?.userId,
        hcfName: shift.facility?.name,
        hcfMSA: shift.facility?.fullAddress?.metropolitanStatisticalArea,
        hcpId: shift.agentId ?? shift.agent?.userId,
        hcpName: shift.agent?.name,
        createdAt: new Date().toJSON(),
      });
    }

    shiftDetailsAlerts.alertReturnToTheFacility({
      facilityName: shift.facility?.name,
      stageText: currentStageText,
      tryAgainBtnHandler: async () => {
        await updateStage(shiftId, shift, stage, setActionLoading)();
      },
      skipLocationBtnHandler: convertToNonInstantPayNoLocation,
    });
  };

  const updateStage =
    (id: string, shift: Shift, stage: ShiftStages, setActionLoading: (_: boolean) => void) =>
    async () => {
      if (stage === ShiftStages.SHIFT_TIME_DONE) {
        setActionLoading(false);
        uploadTimeSheet();
        return;
      }

      setActionLoading(true);
      const humanReadableStage = getHumanReadableTag(stage);

      let location: LegacyGeoLocationCoordinates | undefined;
      let positionError;
      let positionErrorDetails;

      // For certain facility types, we do not want to do location-based validations
      // If we do not want to track agent's location for this shift, we default to using the facility's location
      if (geolocationTrackingForShiftsEnabled) {
        const locationResult = await getOrWatchCurrentLocation(10000);
        ({ location } = locationResult);
        positionError = locationResult.error;
        positionErrorDetails = locationResult.errorDetails;
      } else {
        location = shift.facility?.geoLocation?.coordinates;
        positionError = null;
        positionErrorDetails = null;
      }

      const {
        isLocationExperienceEnabled,
        isOutsideFacilityGeofence,
        location: updatedLocation,
      } = await getGeofenceStatus({
        shift,
        isHyperTrackEnabledForClockInOut,
        checkFacilityGeofence,
      });

      if (isLocationExperienceEnabled && isOutsideFacilityGeofence) {
        const failureReason = `Failed to ${humanReadableStage} to a shift that starts
            on ${shift.start} and ends on ${shift.end} from facility ${shift.facility?.name}
            because user is not inside the Facility's geofence.`;

        showTooFarAwayPopup({
          failureReason,
          stage,
          setActionLoading,
          isLocationExperienceEnabled: true,
          location: updatedLocation,
        });
        setActionLoading(false);
        return;
      }

      if (!isLocationExperienceEnabled) {
        if (positionError) {
          const failureReason = [
            `Failed to ${humanReadableStage} to shift ${shift._id} (starts at ${shift.start} and ends at ${shift.end}) in facility ${shift.facility?.name}.`,
            `Reason: ${LocationAlertTypeReason[positionError]}.`,
            `Error details: ${positionErrorDetails || "N/A"}`,
          ].join(" ");

          dispatch(actionRecordShiftTimeFailure(failureReason, agent, shift._id as string));

          shiftDetailsAlerts.alertLocationAccess({
            openLocationSettingsFn: openLocationSettings,
            skipLocationBtnHandler: convertToNonInstantPayNoLocation,
          });
          setActionLoading(false);
          localStorage.setItem("isLocationAlertOpen", "false");
          return;
        }

        const { coordinates } = shift.facility?.geoLocation as LegacyGeoLocationCoordinatesAndType;
        const distance = calculateGeoDistanceInMiles(
          convertToGeoLocation(coordinates),
          convertToGeoLocation(location as LegacyGeoLocationCoordinates)
        );
        const isLocationAware = checkLocationAwareness(
          shift?.facility?.locationAwareness as string
        );
        const safeDistanceFromFacility =
          env?.facilitySafeDistanceMiles || FACILITY_SAFE_DISTANCE_MILES;

        if (isLocationAware && distance > safeDistanceFromFacility) {
          const failureReason = `Failed to ${humanReadableStage} to a shift that starts
         on ${shift.start} and ends on ${shift.end} from facility ${shift.facility?.name}
         because ${LocationAlertTypeReason[LocationAlertType.NOT_CLOSE]}. Facility
         is at Long/Lat: ${coordinates} and user is at Long/Lat ${location} and the
         distance is ${distance} miles, which is not within the safe facility distance
         of ${safeDistanceFromFacility} miles`;

          showTooFarAwayPopup({
            failureReason,
            stage,
            setActionLoading,
            location,
            isLocationExperienceEnabled: false,
          });
          setActionLoading(false);
          return;
        }
      }

      clockBtnHandler(id, stage, location, shift, setActionLoading);
      localStorage.setItem("isLocationAlertOpen", "false");
    };

  const convertToNonInstantPayNoLocation = async () => {
    try {
      await api.shift.markShiftAsNonInstantPay(
        shift._id as string,
        SHIFT_MARKED_NON_IP_REASONS.NO_LOCATION
      );
      // FIXME - Abstract this out src/app/store/ongoingShifts/actions.ts
      dispatch({
        type: ActionType.UPDATE_SHIFT,
        data: {
          shiftId: shift._id,
          updatedShift: { _id: shift._id, isInstantPay: false },
        },
      });
      await loadShiftDetails(shift._id);
    } catch (error) {
      showErrorToast(error?.response?.body?.error || `Unknown error`);
    }
  };

  if (!shift?.isInstantPay) {
    return null;
  }

  if (
    currentStage === ShiftStages.SHIFT_TIME_DONE &&
    !(
      shift.facility?.verificationPreference?.usesTimesheets || shift.facility?.requireTimecardPhoto
    )
  ) {
    return null;
  }

  const showUploadNowOrLaterModal = () => {
    if (!isNewTimeSheetEnabled || !isDigitalSignatureEnabled) {
      shiftDetailsAlerts.alertUploadTimesheet({
        formattedRemainingAmount: formatRate(remainingAmount || 0),
        is100InstantPayEnabled: shift.instantPayDetails?.is100InstantPayEnabled,
        uploadTimesheetFn: () => uploadTimeSheet(true),
      });
    }
  };

  const handleClockButtonClick = async (params?: { isClockOutConfirmed?: boolean }) => {
    const { isClockOutConfirmed } = params ?? {};
    const shiftCompletedPercent = getShiftCompletionPercentage(shift);
    if (
      currentStage === ShiftStages.LUNCH_OUT &&
      isDefined(shiftCompletedPercent) &&
      shiftCompletedPercent < EARLY_BREAK_ALERT_THRESHOLD_PERCENTAGE
    ) {
      // If trying to start a break too early, show a warning
      const instantPayLogParams = makeInstantpayLogParameters(
        shift,
        shift.isInstantPay,
        undefined,
        shiftStateData?.metadata.requiresLunchBreak
      );

      const shouldStartBreak = await new Promise<boolean>((resolve) => {
        logEvent(USER_EVENTS.TAPPED_START_BREAK_EARLY, instantPayLogParams);
        shiftDetailsAlerts.alertEarlyBreakConfirmation({
          proceedHandler: () => {
            logEvent(USER_EVENTS.STARTED_BREAK_EARLY, instantPayLogParams);
            resolve(true);
          },
          cancelHandler: () => {
            logEvent(USER_EVENTS.CANCELLED_START_BREAK_EARLY, instantPayLogParams);
            resolve(false);
          },
        });
      });
      if (!shouldStartBreak) {
        return;
      }
    }

    if (
      isMandatoryBreakEnabled &&
      currentStage === ShiftStages.LUNCH_IN &&
      breakDuration < MANDATORY_BREAK_DURATION_ALERT_THRESHOLD_IN_MINUTES
    ) {
      const { shouldProceed } = await showMandatoryBreakEarlyEndConfirmation();
      if (!shouldProceed) {
        return;
      }
    }

    if (
      isNewClockOutModalEnabled &&
      currentStage === ShiftStages.CLOCK_OUT &&
      !isClockOutConfirmed
    ) {
      clockOutDialogModalState.openModal();
      return;
    }

    if (
      !isNewClockOutModalEnabled &&
      currentStage === ShiftStages.CLOCK_OUT &&
      isDefined(shiftCompletedPercent) &&
      shiftCompletedPercent < EARLY_CLOCK_OUT_ALERT_THRESHOLD_PERCENTAGE
    ) {
      // If trying to start a break too early, show a warning
      const shouldClockOut = await showEarlyClockOutConfirmation();
      if (!shouldClockOut) {
        return;
      }
    }

    if (
      currentStage === ShiftStages.CLOCK_IN &&
      isBreakPolicyAcknowledgementRequired &&
      !isBreakPolicyAcknowledged
    ) {
      mandatoryBreakPolicyDialogState.openModal();
      logBreakPolicyAcknowledgementEvent(USER_EVENTS.MANDATORY_BREAK_POLICY_VIEWED);

      const isPolicyAccepted = await new Promise((resolve) => {
        deprecatedBreakPolicyAcceptedCallbackRef.current = resolve;
      });
      mandatoryBreakPolicyDialogState.closeModal();

      if (!isPolicyAccepted) {
        return;
      }

      if (mandatoryBreakPolicyNoteId) {
        postBreakPolicyAcknowledgement(
          {
            policyAcknowledgementAction: NoteAcknowledgementAction.CLOCK_IN,
            noteId: mandatoryBreakPolicyNoteId,
          },
          {
            onSettled: () => {
              refetchBreakPolicyAcknowledgement();
            },
          }
        );
      }
    }

    setIsTimekeepingActionLoading(true);
    if (isNFCCheckNeeded) {
      goToNFCInstructionsPage(currentStage);
      setIsTimekeepingActionLoading(false);
    } else {
      updateStage(shift._id as string, shift, currentStage, setIsTimekeepingActionLoading)();
    }
  };

  const handleSkipButtonClick = () => {
    if (isNFCCheckNeeded) {
      goToNFCInstructionsPage(ShiftStages.SKIP_LUNCH);
    } else {
      updateStage(
        shift._id as string,
        shift,
        ShiftStages.SKIP_LUNCH,
        setIsTimekeepingActionLoading
      )();
    }
  };

  return (
    <>
      {shift.end && (
        <ClockOutDialog
          modalState={clockOutDialogModalState}
          shiftEnd={shift.end}
          isTimeSheetEditDisabled={shiftStateData?.metadata.isTimeSheetEditDisabled ?? false}
          onContinue={() => {
            void handleClockButtonClick({ isClockOutConfirmed: true });
            clockOutDialogModalState.closeModal();

            const instantPayLogParams = makeInstantpayLogParameters(
              shift,
              shift.isInstantPay,
              undefined,
              shiftStateData?.metadata.requiresLunchBreak
            );
            logEvent(USER_EVENTS.CLOCKED_OUT_EARLY, {
              ...instantPayLogParams,
              isNewClockOutDialog: true,
            });
          }}
          onCancel={() => {
            const instantPayLogParams = makeInstantpayLogParameters(
              shift,
              shift.isInstantPay,
              undefined,
              shiftStateData?.metadata.requiresLunchBreak
            );
            logEvent(USER_EVENTS.CANCELLED_CLOCKED_OUT_EARLY, {
              ...instantPayLogParams,
              isNewClockOutDialog: true,
            });

            clockOutDialogModalState.closeModal();
          }}
        />
      )}
      <MandatoryBreakPolicyDialog
        modalState={mandatoryBreakPolicyDialogState}
        contentLines={mandatoryBreakPolicyNoteContent?.split("\n") || []}
        acknowledgementAction={NoteAcknowledgementAction.CLOCK_IN}
        onClose={() => {
          logBreakPolicyAcknowledgementEvent(USER_EVENTS.MANDATORY_BREAK_POLICY_CANCELLED);
          deprecatedBreakPolicyAcceptedCallbackRef.current?.(false);
        }}
        onContinue={() => {
          logBreakPolicyAcknowledgementEvent(USER_EVENTS.MANDATORY_BREAK_POLICY_ACCEPTED);
          deprecatedBreakPolicyAcceptedCallbackRef.current?.(true);
        }}
      />

      <IonButton
        data-testid="shift-clock-button"
        size="large"
        expand="block"
        shape="round"
        color="primary"
        disabled={!isRecordTimeButtonEnabled || isTimekeepingActionLoading || isBreakPolicyLoading}
        className="ion-margin"
        onClick={() => handleClockButtonClick()}
      >
        {(isTimekeepingActionLoading || isBreakPolicyLoading) && <IonSpinner name="crescent" />}
        {currentStageText}
      </IonButton>
      {currentStage === ShiftStages.SHIFT_TIME_DONE && !isSignatureSubmission && (
        <IonLabel className="no-timesheet-select" onClick={onClickNoTimeSheet}>
          I don’t have a timesheet
        </IonLabel>
      )}
      <SkipLunchButton
        stage={currentStage}
        shift={shift}
        shiftStateData={shiftStateData}
        onClick={handleSkipButtonClick}
        loading={isTimekeepingActionLoading}
      />
    </>
  );
}
